<template>
  <form
    novalidate
    class="reset-password-form"
    @submit.prevent="signIn"
  >
    <VInput
      name="email"
      type="email"
      :placeholder="$t('Email')"
      :value="form.email"
      :generalError="rulesForGeneralError"
      :disabled="inputDisabled"
      required
      class="reset-password-form__input"
      @change-input-value="changeForm($event, 'email')"
      @input-errors="onInputErrors"
      @show-error="onBlurInputWithError"
      @hide-error="onFocusInputWithError"
    >
      <IconEmail />
    </VInput>

    <ErrorsLog
      :errorsShowed="errorsShowed"
      :errors="errors"
      :generalError="generalError"
      :booleanRulesForGeneralError="booleanRulesForGeneralError"
      @error-enter="erorrEnter"
      @error-after-leave="errorAfterLeave"
      @general-error-enter="generalErrorEnter"
      @general-error-after-leave="generalErrorAfterLeave"
    />

    <VButton :isLoading="isLoading">
      {{ $t('Log in') }}
    </VButton>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { useKriptid } from '@/utils/helpers'
import { captureException } from '@sentry/browser'
import { validate } from '@/utils/validate'
import { errorsLog } from '@/mixins/errorsLog'
import { authForms } from '@/mixins/authForms'
import ErrorsLog from '@/components/auth/ErrorsLog'
import VInput from '@/components/ui/Auth/VInput'
import VButton from '@/components/ui/Auth/VButton'
import IconEmail from '@/assets/icons/contact-us.svg'

export default {
  name: 'SocialAuthForm',
  components: {
    ErrorsLog,
    VInput,
    VButton,
    IconEmail,
  },
  mixins: [
    errorsLog,
    authForms,
  ],

  data: () => ({
    url: '',
    flash: '',
    alreadyUsed: '',
    pending: false,
    form: {
      code: null,
      provider: null,
      email: '',
      token: null,
      state: null,
    },
  }),
  computed: {
    ...mapState('user', [
      'user',
    ]),

    statusAllFields () {
      return this.form.email &&
        this.generalError.text === validate.generalErrors.allHighlightedFieldsMustBeFilled
    },
    urlIsValid () {
      return this.url.searchParams.get('provider') &&
        this.url.searchParams.get('code') && this.url.searchParams.get('state')
    },
  },

  created () {
    this.url = new URL(window.location.href)

    if (!this.urlIsValid) {
      this.$router.push('sign-in')
    }

    this.form.provider = this.url.searchParams.get('provider')
    this.form.code = this.url.searchParams.get('code')
    this.form.state = this.url.searchParams.get('state')
  },
  async mounted () {
    try {
      const response = await this.axios.post('/user/social/get-token', this.form)

      if (response.data.success) {
        this.form.token = response.data.token

        await this.signIn()
      }
    } catch (e) {
      captureException(e)
    }
  },

  methods: {
    ...mapActions('auth', [
      'signInSuccess',
    ]),

    ...mapActions('user', [
      'getUserData',
    ]),

    setLoading (bool) {
      this.$emit('set-loading', bool)
    },
    async signIn () {
      this.setFormLoadingState(true)

      try {
        await this.getUserData()

        if (!this.user) {
          return this.setGeneralError(true, validate.generalErrors.tryAgain)
        }

        try {
          const response = await this.axios.post('/user/social/connect', {
            provider: this.form.provider,
            token: this.form.token,
          })

          if (!response.data.success) {
            this.setLoading(false)
            this.setFormLoadingState(false)
            this.setGeneralError(true, validate.generalErrors.tryAgain)

            return
          }

          this.$router.push({ name: 'settings-profile' })
        } catch (e) {
          this.setLoading(false)
          this.setFormLoadingState(false)

          const error = e.response
            ? e.response.data.message
            : e.message

          if (error === 'Network Error') {
            return this.setGeneralError(true, validate.generalErrors.connectionFalse)
          }

          this.setGeneralError(true, validate.generalErrors.tryAgain)
        }
      } catch (e) {
        try {
          const response = await this.axios.post('/user/social/confirm', this.form)
          this.setFormLoadingState(false)

          const responseDataSuccess = response.data.success && response.data.status === 'ok'

          if (!responseDataSuccess) {
            this.setLoading(false)
            this.setFormLoadingState(false)

            return
          }
          if (response.data.isNewUser) {
            useKriptid(1, 'event', 'mainRegistration', { source: this.form.provider })
          }

          this.signInSuccess()
        } catch (e) {
          this.setLoading(false)
          this.setFormLoadingState(false)

          const error = e.response
            ? e.response.data.message
            : e.message

          if (error === 'Network Error') {
            return this.setGeneralError(true, validate.generalErrors.connectionFalse)
          }

          this.setGeneralError(true, validate.generalErrors.tryAgain)
        }
      }
    },
  },

  i18n: {
    messages: {
      'en': {
        Email: 'Email',
        'Log in': 'Log in',
      },
      'ru': {
        Email: 'Электронная почта',
        'Log in': 'Войти',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.reset-password-form {
  display: grid;
}

.reset-password-form__input {
  margin-bottom: 20px;
}
</style>
